// @ts-strict-ignore
import React, { useContext, useEffect, useState } from "react"

import { ResizeObserver } from "@juggle/resize-observer"
import * as GeoJSON from "geojson"
import mapboxgl, { LineLayout } from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import MapGL, { Layer, Marker, Source, ViewState } from "react-map-gl"
import useMeasure from "react-use-measure"
import styled, { ThemeContext } from "styled-components"

import ButtonLink from "@kiwicom/orbit-components/lib/ButtonLink"
import InputField from "@kiwicom/orbit-components/lib/InputField"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import Textarea from "@kiwicom/orbit-components/lib/Textarea"
import Tooltip from "@kiwicom/orbit-components/lib/Tooltip"

import EmberGeolocateControl from "components/generic/map/ember-geolocate-control"
import EmberNavigationControl from "components/generic/map/ember-navigation-control"
import EmberScaleControl from "components/generic/map/ember-scale-control"
import {
  Column,
  ColumnContext,
  MultiColumnScrollSection,
  MultiColumnWrapper,
  PaddedSection,
} from "components/generic/multi-column-layout"
import { AdminLayout } from "components/layout-custom"
import { StopMarkerIcon } from "components/route-map/map-styles"

import { fetchFromAPIBase } from "utils/fetch-utils"
import { setQueryString } from "utils/url-utils"

const MapWrapper = styled.div`
  flex: 1;
  cursor: crosshair;
  .mapboxgl-popup-content {
    font-weight: 400;
    padding: 10px 15px !important;
  }
  .popup-title {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 4px;
  }
`

const MapMobileWrapper = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
`

const lineLayout: LineLayout = {
  "line-cap": "round",
  "line-join": "round",
}

const createXmlString = (lines: number[][]): string => {
  // Create an XML version of a GPX file, for download
  let result =
    '<gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" version="1.1" creator="runtracker"><metadata/><trk><name></name><desc></desc>'
  let segmentTag = "<trkseg>"
  segmentTag += lines
    .map((point) => `<trkpt lat="${point[1]}" lon="${point[0]}"></trkpt>`)
    .join("")
  segmentTag += "</trkseg>"
  result += segmentTag
  result += "</trk></gpx>"
  return result
}

const downloadGpxFile = (lines: number[][]) => {
  const xml = createXmlString(lines)
  const url = "data:text/json;charset=utf-8," + xml
  const link = document.createElement("a")
  link.download = "route.gpx"
  link.href = url
  document.body.appendChild(link)
  link.click()
}

// This is adapted from the implementation in Project-OSRM
// https://github.com/DennisOSRM/Project-OSRM-Web/blob/master/WebContent/routing/OSRM.RoutingGeometry.js
function decode(str) {
  // Decode a polyline
  let index = 0,
    lat = 0,
    lng = 0,
    shift = 0,
    result = 0,
    byte = null,
    latitude_change,
    longitude_change
  const coordinates = []
  const precision = 6
  const factor = Math.pow(10, precision || 6)

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {
    // Reset shift, result and byte
    byte = null
    shift = 0
    result = 0

    do {
      byte = str.charCodeAt(index++) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    latitude_change = result & 1 ? ~(result >> 1) : result >> 1

    shift = result = 0

    do {
      byte = str.charCodeAt(index++) - 63
      result |= (byte & 0x1f) << shift
      shift += 5
    } while (byte >= 0x20)

    longitude_change = result & 1 ? ~(result >> 1) : result >> 1

    lat += latitude_change
    lng += longitude_change

    coordinates.push([lat / factor, lng / factor])
  }

  return coordinates
}

// @ts-ignore Load in worker for mapbox-gl
mapboxgl.workerClass =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

interface MapProps {
  fullRoute: any
  pointSetter: any
  stops: [number, number][]
  viewState: any
  setViewState: (e: ViewState) => void
}

const Map = ({
  fullRoute,
  pointSetter,
  stops,
  viewState,
  setViewState,
}: MapProps) => {
  const [cursor, setCursor] = useState<string>("auto")

  const theme = useContext(ThemeContext)
  const linePaint = {
    "line-color": theme.orbit.paletteProductNormal,
    "line-width": 5,
    "line-opacity": 0.9,
  }

  const routeGeoJSON: GeoJSON.Feature = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: fullRoute,
    },
  }

  useEffect(() => {
    setCorrectCursor()
  }, [pointSetter])

  const setCorrectCursor = () => {
    if (pointSetter != null) {
      setCursor("crosshair")
    } else {
      setCursor("grab")
    }
  }

  return (
    <MapGL
      mapboxAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
      mapStyle="mapbox://styles/mapbox/outdoors-v11?optimize=true"
      onClick={(event) => {
        pointSetter ? pointSetter(event.lngLat) : null
      }}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      style={{ cursor: "crosshair" }}
      onRender={setCorrectCursor}
      cursor={cursor}
    >
      <EmberGeolocateControl />
      <EmberNavigationControl />
      <EmberScaleControl />
      {stops &&
        stops.map((stop, index) => {
          return (
            <Marker
              key={`stop-${index}`}
              longitude={stop[0]}
              latitude={stop[1]}
            >
              <StopMarkerIcon />
            </Marker>
          )
        })}
      {fullRoute.length > 0 && (
        <Source id="route" type="geojson" data={routeGeoJSON}>
          <Layer id="route" type="line" layout={lineLayout} paint={linePaint} />
        </Source>
      )}
    </MapGL>
  )
}

const Page = () => {
  const [length, setLength] = useState(13.5)
  const [height, setHeight] = useState(3.6)
  const [width, setWidth] = useState(2.55)
  const [weight, setWeight] = useState(18.5)
  const [advancedOptions, setAdvancedOptions] = useState({
    use_highways: 1.0,
    use_living_roads: 0.01,
    maneuver_penalty: 150,
  })
  const [advancedOptionsInputText, setAdvancedOptionsInputText] = useState(() =>
    JSON.stringify(advancedOptions, undefined, 4)
  )
  const [advancedOptionsInputError, setAdvancedOptionsInputError] =
    useState(null)
  const [stops, setStops] = useState<[number, number][]>([])
  const [isSelectingPoint, setIsSelectingPoint] = useState<boolean>(true)
  const [routingResponse, setRoutingResponse] = useState(null)
  const [ref, _bounds] = useMeasure({ polyfill: ResizeObserver })

  const initialViewState = {
    longitude: -4,
    latitude: 56,
    zoom: 8,
  }

  const [viewState, setViewState] = useState(initialViewState)

  const pointSetter = (lngLat: mapboxgl.LngLat) => {
    setStops([...stops, [lngLat.lng, lngLat.lat]])
  }

  const updateURL = () => {
    setQueryString({
      state: JSON.stringify({
        stops: stops,
        weight: weight,
        height: height,
        width: width,
        length: length,
        viewState: {
          latitude: viewState.latitude,
          longitude: viewState.longitude,
          zoom: viewState.zoom,
        },
      }),
    })
  }

  useEffect(() => {
    // Use stops from query parameters if possible
    const params = new URLSearchParams(window.location.search)
    const state = params.get("state")
    if (state) {
      const parsedState = JSON.parse(state)
      if (parsedState.weight) {
        setWeight(parsedState.weight)
      }
      if (parsedState.height) {
        setHeight(parsedState.height)
      }
      if (parsedState.width) {
        setWidth(parsedState.width)
      }
      if (parsedState.length) {
        setLength(parsedState.length)
      }
      if (parsedState.viewState) {
        setViewState(parsedState.viewState)
      }
      if (parsedState.stops) {
        setStops(parsedState.stops)
      }
    }
  }, [])

  useEffect(() => {
    getRouting()
    updateURL()
  }, [stops])

  useEffect(() => {
    getRouting()
  }, [JSON.stringify(advancedOptions)])

  // When the advanced options are modified in the text box, try to parse that as JSON. If it
  // succeeds, update the options. If it fails, display the error.
  useEffect(() => {
    try {
      setAdvancedOptions(JSON.parse(advancedOptionsInputText))
      setAdvancedOptionsInputError(null)
    } catch (e) {
      setAdvancedOptionsInputError(`${e}`)
    }
  }, [advancedOptionsInputText])

  const getRouting = () => {
    if (stops.length < 2) {
      // Can't compute the route with less than 2 stops
      return null
    }
    const payload = {
      locations: stops.map((lngLat) => {
        return { lat: lngLat[1], lon: lngLat[0], type: "through" }
      }),
      costing: "bus",
      costing_options: {
        bus: {
          top_speed: 100,
          weight: weight,
          width: width,
          height: height,
          length: length,
          ...advancedOptions,
        },
      },
    }
    fetchFromAPIBase({
      path: `/routing/route?json=${JSON.stringify(payload)}`,
    }).subscribe((response) => {
      if (response && !response.error) {
        setRoutingResponse({
          timeMinutes: response.trip.summary.time / 60,
          lengthKm: response.trip.summary.length,
          cost: response.trip.summary.cost,
          coordinates: decode(response.trip.legs[0].shape).map((coords) => [
            coords[1],
            coords[0],
          ]),
        })
      }
    })
  }

  return (
    <AdminLayout title="Routing" padded={false}>
      <MultiColumnWrapper>
        <ColumnContext.Consumer>
          {(context) => (
            <>
              <Column>
                <MultiColumnScrollSection>
                  <PaddedSection>
                    <Stack spacing="XLarge">
                      <Text>
                        Plot a route for a bus, with a top speed of
                        100&nbsp;km/h
                      </Text>
                      <Stack direction="row" align="end">
                        <InputField
                          label="Length (m)"
                          onChange={(event) => {
                            setLength(event.currentTarget.valueAsNumber)
                          }}
                          inputMode="numeric"
                          type="number"
                          value={length}
                        />
                        <InputField
                          label="Width (m)"
                          onChange={(event) => {
                            setWidth(event.currentTarget.valueAsNumber)
                          }}
                          inputMode="numeric"
                          type="number"
                          value={width}
                        />
                        <InputField
                          label="Height (m)"
                          onChange={(event) => {
                            setHeight(event.currentTarget.valueAsNumber)
                          }}
                          inputMode="numeric"
                          type="number"
                          value={height}
                        />
                        <InputField
                          label="Weight (t)"
                          onChange={(event) => {
                            setWeight(event.currentTarget.valueAsNumber)
                          }}
                          inputMode="numeric"
                          type="number"
                          value={weight}
                        />
                      </Stack>
                      <Textarea
                        label="Advanced Options"
                        error={advancedOptionsInputError}
                        onChange={(event) => {
                          setAdvancedOptionsInputText(event.currentTarget.value)
                        }}
                        rows={5}
                        value={advancedOptionsInputText}
                        placeholder=""
                      />
                      <Stack direction="column">
                        <ButtonLink
                          onClick={() => {
                            setIsSelectingPoint(!isSelectingPoint)
                          }}
                        >
                          {isSelectingPoint ? "Stop adding stops" : "Add stops"}
                        </ButtonLink>
                        <ButtonLink
                          onClick={() => {
                            setStops(stops.slice(0, stops.length - 1))
                          }}
                          disabled={stops.length === 0}
                        >
                          Remove last stop
                        </ButtonLink>
                        <ButtonLink
                          onClick={() => {
                            downloadGpxFile(routingResponse.coordinates)
                          }}
                          disabled={!routingResponse?.coordinates}
                        >
                          Download route GPX
                        </ButtonLink>
                      </Stack>
                      {routingResponse && (
                        <Stack spacing="XSmall">
                          <Stack direction="row">
                            <Tooltip
                              content={
                                <Text>
                                  The time in minutes. As of October 2021, we
                                  have not asserted the reliability of this
                                  timing and it does not take into account
                                  traffic estimates.
                                </Text>
                              }
                            >
                              <Text weight="bold">Time: </Text>
                            </Tooltip>
                            <Text>
                              {Math.round(routingResponse.timeMinutes)} minutes
                            </Text>
                          </Stack>
                          <Stack direction="row">
                            <Text weight="bold">Distance: </Text>
                            <Text>
                              {Math.round(routingResponse.lengthKm * 10) / 10}{" "}
                              km
                            </Text>
                          </Stack>
                          <Stack direction="row">
                            <Tooltip
                              content={
                                <Text>
                                  Valhalla (the routing engine) computes a cost
                                  for each possible route. The route with the
                                  lowest cost will be chosen. We include the
                                  cost here to help understand why Valhalla is
                                  making certain routing choices. For example,
                                  it can help diagnose whether an odd choice of
                                  route is caused by a map issue, or a routing
                                  engine issue.
                                </Text>
                              }
                            >
                              <Text weight="bold">Cost: </Text>
                            </Tooltip>
                            <Text>
                              {Math.round(routingResponse.cost * 10) / 10}
                            </Text>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </PaddedSection>
                  {context.numberColumns == 1 && (
                    <MapMobileWrapper>
                      <MapWrapper ref={ref}>
                        <Map
                          fullRoute={routingResponse?.coordinates || []}
                          pointSetter={isSelectingPoint ? pointSetter : null}
                          stops={stops}
                          viewState={viewState}
                          setViewState={setViewState}
                        />
                      </MapWrapper>
                    </MapMobileWrapper>
                  )}
                </MultiColumnScrollSection>
              </Column>
              <Column>
                <MapWrapper ref={ref}>
                  <Map
                    fullRoute={routingResponse?.coordinates || []}
                    pointSetter={isSelectingPoint ? pointSetter : null}
                    stops={stops}
                    viewState={viewState}
                    setViewState={setViewState}
                  />
                </MapWrapper>
              </Column>
            </>
          )}
        </ColumnContext.Consumer>
      </MultiColumnWrapper>
    </AdminLayout>
  )
}

export default Page
